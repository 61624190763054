import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d8c2c74 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _d9d75106 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _aaafc1f8 = () => interopDefault(import('../pages/main_console.vue' /* webpackChunkName: "pages/main_console" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login/",
    component: _3d8c2c74,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/logout/",
    component: _d9d75106,
    pathToRegexpOptions: {"strict":true},
    name: "logout"
  }, {
    path: "/main_console/",
    component: _aaafc1f8,
    pathToRegexpOptions: {"strict":true},
    name: "main_console"
  }, {
    path: "/",
    redirect: "/main_console/",
    name: "index"
  }, {
    path: "*",
    redirect: "/main_console/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
